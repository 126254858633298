import "./SignUpSeller.scss";

export default function SignUpSeller() {
  return (
    <div className="signUpSeller">
      {/* <Router>
        <StepOne path="/stepOne" />
        <StepTwo path="/stepTwo" />
        <StepThree path="/stepThree" />
      </Router> */}
    </div>
  );
}
